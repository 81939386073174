/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'gatsby';

import { useObserver } from '@/hooks';

import { Container, Text } from './styles';

const Career = () => {
  const { setPosition } = useObserver();

  return (
    <Container>
      <Text onTouchStart={setPosition} onMouseEnter={setPosition}>
        Unser Verein engagiert sich für die Chancengleichheit im Medizinstudium. Wir möchten, dass die Talente von
        Medizinstudent/innen – unabhängig von der Herkunft – optimal gefördert werden. Unser Förderprogramm richtet sich gezielt
        an Medizinstudent/innen mit schwierigen Startbedingungen. Mittels einer individualisierten Karriereplanung bieten wir
        zukünftigen Mediziner/innen Zugang zu unserem Netzwerk und zu renommierten Ärzten in ihren Interessensgebieten. Die von
        uns geförderten Medizinstudent/innen beteiligen sich zudem an klinischen Forschungsprojekten und publizieren Ergebnisse in
        angesehenen wissenschaftlichen Zeitschriften. Sie werden so optimal auf die klinische Laufbahn und eine akademische
        Karriere vorbereitet.
      </Text>
      <Text onTouchStart={setPosition} onMouseEnter={setPosition}>
        Hast du Interesse an unseren Förderprogrammen? <Link to="/kontakt/">Sende uns</Link> deinen Lebenslauf und deine
        Karrierewünsche. Wir sind gerne für dich da.
      </Text>
    </Container>
  );
};

export default Career;
