import styled from 'styled-components';

import { fonts } from '@/theme';

export const Container = styled.div`
  padding: 15px 20px;
  @media (max-width: 991px) {
    padding: 6px 10px;
  }
`;

export const Text = styled.p`
  margin-bottom: 1em;
  &,
  & > a {
    ${fonts.text}
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    &,
    & > a {
      ${fonts.smallText22}
    }
  }
`;
